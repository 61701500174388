.DanonePopupPOI {
  &__popup {
    width: 300px;
    padding: 1rem;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: .5rem;
      .icon {
        width: auto;
        height: auto;
      }
      .wrapper-title {
        .title {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          width: 13rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .subtitle {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #A0A6A9;
        }
      }
    }

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      font-size: 14px;
      margin-bottom: 0.25rem;

      :first-child {
        color: #878A92;
      }

      :last-child {
        color: #616161;
        font-weight: 600;
      }
    }

    .address {
      max-width: 8rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .line {
      border-bottom: 1px solid #E1E1E1;
      margin-bottom: 0.25rem;
    }
  }
  &.MarkerCluster__child-tooltip {
    .leaflet-popup-close-button {
      border: none !important;
    }
  }
}