.feature-pertamina {
  &.PertaminaDownloadNotification {
    position: absolute;
    top: 8.5rem;
    right: 1rem;
    width: 15.6875rem;
    background: #fff;
    z-index: 999999;
    .container {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid #e1e1e1;
      background: #fff;
      .header {
        color: #616161;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }
      .content {
        .item {
          display: flex;
          justify-content: space-between;
          .name {
            color: #101010;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 70%; 
          }
          .persen {
            color: #888;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
          }
        }
        .size {
          display: block;
          color: #888;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
        .information {
          color: #101010;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
      }
    }
  }
}

@keyframes progress-loading {
    100% {
      background-position: -200% 0;
    }
  }
