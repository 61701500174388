$open-legend-width: 14.375rem;
$close-legend-width: 6rem;

@keyframes showLegend {
  0% {
    width: $close-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    width: $open-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes hideLegend {
  0% {
    width: $open-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    width: $close-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes showContent {
  0% {
    width: $close-legend-width;
    min-height: 0rem;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    width: $open-legend-width;
    min-height: 2.75rem;
    -webkit-transform: 100% 100%;
    transform-origin: 100% 100%;
    visibility: visible;
  }
}

@keyframes hideContent {
  0% {
    width: $open-legend-width;
    min-height: 2.75rem;
    -webkit-transform: 100% 100%;
    transform-origin: 100% 100%;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    width: $close-legend-width;
    height: 0rem;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    visibility: hidden;
  }
}

@keyframes rotateIconShow {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotateIconHide {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.danone__legend {
  position: fixed;
  z-index: 1200;
  bottom: 3rem;
  right: 1rem;
  cursor: default;
  transition: 0.25s ease-in-out;

  &.hidden {
    display: none;
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    border: 1px solid #caced5;
    border-radius: 0.25rem;
    background-color: #ffffff80;
    -webkit-animation: hideContent 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: hideContent 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    max-height: 285px;
    // visibility: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &.show {
      -webkit-animation: showContent 0.25s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: showContent 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      // visibility: visible;
    }

    &__search {
      margin-bottom: 0.5rem;
      .ant-input-wrapper {
        display: flex;
        width: 100%;
        .ant-input {
          background: #ffffff;
          border: 1px solid #ced4da;
          border-radius: 4px 0px 0px 4px;
          padding: 0.3125rem 0.75rem;
          &::placeholder {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.25rem;
            color: #ced4da;
          }
        }
        .ant-input-group-addon {
          display: flex;
          flex-direction: column;
          background: #f8f8fb;
          border: 1px solid #ced4da;
          border-radius: 0px 4px 4px 0px;
          padding: 0.5rem;
          width: fit-content;
          border-left: none;
          svg {
            width: 0.875rem;
            height: 0.875rem;
          }
        }
      }
    }

    &__ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.38rem;

      .legend-collapse {
        width: 100%;
        background-color: transparent;
        border-radius: 6px;

        .legend-collapse-header {
          border: none;
          .ant-collapse-header {
            padding: 6px 12px;
            flex-direction: row-reverse;
            background-color: #ffffff;

            .ant-collapse-header-text {
              color: #00b4e8;
            }
          }
          .ant-collapse-content {
            background-color: transparent;
            .ant-collapse-content-box {
              padding: 0 6px 6px 6px;

              .legend-collapse-content {
                .legend-collapse-sub-title {
                  background-color: #fff;
                  padding: 4px 12px;
                  margin-bottom: 6px;
                  margin-left: -6px;
                  margin-right: -6px;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  color: #495057;
                }
                .legend-collapse-content-item {
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }

    &__li {
      border: 1px solid #caced5;
      border-radius: 0.25rem;
      background-color: #ffffff96;
      display: flex;
      align-items: center;
      margin: 0rem 0rem 0.25rem 0rem;
      width: 100%;
      padding: 0.5rem 0.75rem;
      gap: 0.69rem;

      &--desc {
        color: #495057;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }

      &--icon {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
      }

      &.center {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
      .custom-checkbox {
        width: 1rem;
        height: 1rem;
        border: 2px solid #07827c;
        background-color: #fff;
        display: inline-block;
        cursor: pointer;
        position: relative;
        border-radius: 4px;
        flex-shrink: 0;
      }

      .custom-checkbox.checked {
        background-color: #07827c;
      }

      .custom-checkbox::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 3.4px;
        width: 6px;
        height: 12px;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(45deg);
        display: none;
      }

      .custom-checkbox.checked::after {
        display: block;
      }
    }

    &__title_parent {
      color: #495057;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      padding-bottom: 0.25rem;
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 0.5rem;
    }
  }

  &__action {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__button {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 1.875rem;
    animation: hideLegend 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    border-radius: 0.25rem;
    border: 1px solid #d7dae2;

    &.show {
      -webkit-animation: showLegend 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: showLegend 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &--arrow {
      margin: 0 0 0 0.5rem;
      fill: #007e75;
      -webkit-animation: rotateIconHide 0.12s ease-in-out both;
      animation: rotateIconHide 0.12s ease-in-out both;

      &.show {
        -webkit-animation: rotateIconShow 0.12s ease-in-out both;
        animation: rotateIconShow 0.12s ease-in-out both;
      }
    }

    &__container {
      display: flex;
      align-items: center;
    }
  }
}
