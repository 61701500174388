.DanonePopupTerritory {
  &__popup {
    cursor: default;
    width: 300px;
    padding: 1rem;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      .icon {
        width: auto;
        height: auto;
      }
      .wrapper-title {
        .title {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          width: 13rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .subtitle {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #a0a6a9;
        }
      }
    }

    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      font-size: 14px;
      margin-bottom: 0.25rem;

      :first-child {
        color: #878a92;
      }

      :last-child {
        color: #616161;
        font-weight: 600;
      }
    }

    .button {
      width: 100%;
      margin-top: 0.75rem;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid #007e75;
      background-color: #007e75;
      color: #fff;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }

    .address {
      max-width: 10rem;
      text-wrap: wrap;
      text-align: right;
    }

    .line {
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 0.25rem;
    }
  }
  &.Popup__child-tooltip {
    .leaflet-popup-close-button {
      border: none !important;
    }
  }
}
